























































































































import { Component, Vue } from "vue-property-decorator";
import MyList from "@/components/MyList.vue";
import Mixin from "@/js/mixins";

@Component({ components: { MyList }, mixins: [Mixin] })
export default class listorder extends Vue {
  public tab_list = [
    { name: "全部订单", value: "" },
    { name: "待处理", value: "101" },
    { name: "待发货", value: "102" },
    { name: "已发货", value: "103" },
    { name: "已完成", value: "104" }
  ]; // 头部导航
  public active = 0; // 头部导航选中位置
  public timeTypeList = [
    {text:'下单时间',value:'add_time'},
    {text:'支付时间',value:'pay_time'},
    {text:'发货时间',value:'shipping_time'},
  ]; // 头部导航选中位置

  public filteList = [
    {text:'全部',value:''},
    {text:'店中店',value:'1'},
    {text:'艾灸馆',value:'2'},
  ];
  public filteIndex = 0

  filte(index){
    this.filteIndex = index
    this.list = [];
    this.refresh++;
  }

  public timeType = "";
  public isShowTime = false;
  public showCalendar = false;
  public startTime = '';
  public endTime = '';
  public listInfo:any = {};

  public user_id = "";
  public search = ""; // 搜索框的内容
  public search_value = ""; // 点搜索按钮后搜索点内容
  public list: any[] = []; // 数据的列表
  public refresh = 0; // 页面允许刷新（为了list的改变）

  clearList() {
    this.list = [];
  }
  setList(list: any[]) {
    this.list.push(...list);
  }
  getInfo(e){
    console.log(e) 
    this.listInfo = e
  }
  clearTime(){
    this.timeType = '',
    this.startTime = '',
    this.endTime = ''
  }
  //格式化日期
  formatDate(date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }
  //获取比现在早一天时间
  formatDateb(date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()-1}`;
  }
  // 筛选
  shaixuan(){
    this.isShowTime = false
  }
  // 选择日期类型
  changeTimeType(item){
    this.timeType = item.value
    this.startTime = this.formatDateb(new Date())
    this.endTime = this.formatDate(new Date())
  }
  // 选择时间区间
  onConfirm(e){
    this.startTime = this.formatDate(e[0])
    this.endTime = this.formatDate(e[1])
    this.showCalendar = false
  }

  init() {
    this.user_id = String(this.$route.query.id || "");
    this.active = Number(this.$route.query.active || 0);
    this.search = "";
    this.search_value = "";
    this.list = [];
    this.refresh++;
  }
}
